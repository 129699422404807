import React from 'react';

const Term = () => {
  return (
    <div>
      <h2>Terms & Conditions for Corporate Tournament Bookings</h2>
      <p>Thank you for considering Machaxi for hosting your corporate tournament. Please take a moment to review our booking policies to ensure a seamless experience:</p>
      
      <h3>1. Quotation and Availability</h3>
      <ul>
        <li>
          The provided quotation is for inquiry purposes only and does not guarantee slot or service availability. Slot confirmation will be communicated by our executives through your preferred mode of contact—via phone, WhatsApp, or email.
        </li>
        <li>
          Add-on services are excluded from the initial quotation and will be included in the final invoice upon confirmation from our executives and partnered vendors.
        </li>
      </ul>
      
      <h3>2. Food Services Policy</h3>
      <ul>
        <li>
          Outside food services are strictly prohibited. Exceptions may be made for certain Machaxi venues, but at an additional cost. It is the customer's responsibility to inform us about external food arrangements before confirming the event or making any payments. Machaxi reserves the right to deny external food services if not pre-approved, and no refunds will be issued in such cases.
        </li>
      </ul>
      
      <h3>3. Cancellation Policy</h3>
      <ul>
        <li><strong>More than 10 days in advance</strong>: No cancellation fees apply.</li>
        <li><strong>5-10 days in advance</strong>: A 20% cancellation fee will be charged based on the total invoice amount.</li>
        <li><strong>3-5 days in advance</strong>: A 50% cancellation fee will apply.</li>
        <li><strong>Less than 3 days in advance</strong>: The full invoice amount will be charged as a penalty.</li>
      </ul>

      <h3>4. Rescheduling Policy</h3>
      <ul>
      <li>Rescheduling of the event can be requested up to 7 days before the tournament date. Any rescheduling requests made after this period may not be accommodated.</li>
      </ul>
      <h3>5. Add-on Services</h3>
      <ul>
      <li>Cancellation or rescheduling of any confirmed add-on services (e.g., equipment, catering, etc.) is not permitted. The full invoice amount for these services will be charged regardless of cancellation.</li>
      </ul>
      <p>We appreciate your understanding and cooperation in adhering to these terms. Should you have any questions or require further clarification, please feel free to contact us.</p>

     
    </div>
  );
};

export default Term;
