import React from 'react';
import BuyPlan from './components/BuyPlan';

function App() {
  return (
    <>
      <BuyPlan />
    </>
  );
}

export default App;
