import React from 'react';
import '../style/NavBar.css'; // Ensure to import the CSS
import logo from '../images/Machaxi-03.png';
const Navbar = () => {
  return (
    <div className="navbar-container">
      <nav className="navbar">
      <div>
  <img src={logo} alt="Company Logo" className="navbar-logo" />
</div>

        <h1 className="navbar-title">Welcome to Machaxi</h1>
      </nav>
    </div>
  );
};

export default Navbar;
